<template>
    <div id="app">
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
};
</script>

<style>
#app {
    text-align: center;
    background: linear-gradient(to right, rgba(135, 206, 250, 0.4), rgba(255, 182, 193, 0.4));
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

body {
    display: block;
    margin: 0;
}
</style>
